import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import pxToRem from "assets/theme/functions/pxToRem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/header-home.jpg";

// Icon
import { Launch } from "@mui/icons-material";

function Header({ image, title, subtitle, links, half }) {
  console.log(title);
  return (
    <>
      {half ? (
        <MKBox
          minHeight="50vh"
          width="100%"
          margin="0"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "end start",
          }}
        >
          <Container width="100%">
            <Grid
              container
              item
              xs={12}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  fontSize: pxToRem(61),
                  lineHeight: 1.25,
                })}
              >
                {title}
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      ) : (
        <MKBox
          minHeight="100vh"
          width="100%"
          margin="0"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.7)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "end start",
          }}
        >
          <Container width="100%">
            <Grid
              container
              item
              xs={12}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  fontSize: pxToRem(61),
                  lineHeight: 1.25,
                })}
              >
                {title}
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                width="100%"
                mt={1}
                pr={{ md: 12, lg: 12, xl: 12 }}
                opacity={0.9}
              >
                {subtitle}
              </MKTypography>
              <Stack direction="row" spacing={1} mt={6} mb={8}>
                {links.map(({ link, linkText, linkExt, linkAriaLabel }, key) => (
                  <MKButton
                    key={link}
                    variant="outlined"
                    color="white"
                    mr={key === links.length - 1 ? 0 : 3}
                    mb={3}
                    href={link}
                    target={linkExt ? "_blank" : "_self"}
                    aria-label={linkAriaLabel}
                  >
                    {linkText}
                    {linkExt ? <Launch /> : null}
                  </MKButton>
                ))}
              </Stack>
            </Grid>
          </Container>
        </MKBox>
      )}
    </>
  );
}

// Setting default values for the props of HeaderHalf
Header.defaultProps = {
  image: bgImage,
  title: "Default Title",
  subtitle: "Default Subtitle",
  links: [],
  half: false,
};

// Typechecking props for the HeaderHalf
Header.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  links: PropTypes.array,
  half: PropTypes.bool,
};

export default Header;
