/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import { Padding } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image from "assets/images/eu-flag.jpg";

function Testimonials() {
  return (
    <MKBox component="section" position="relative" pt={16} pb={6}>
      <MKBox variant="gradient" bgColor="dark" width="100%" py={5}>
        <Container sx={{ maxWidth: "100% !important" }}>
          <Grid container item xs={10} sx={{ mx: "auto" }}>
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                <MKBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 0 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <MKBox sx={{ color: ({ palette: { white } }) => white.main }}>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="light"
                    mb={0}
                    sx={{ fontSize: "1rem" }}
                  >
                    &ldquo;The opt-out approach is in the law and seems to be gathering momentum,
                    both in practice and in EU policy making.&rdquo;
                  </MKTypography>
                  <MKTypography variant="button" fontWeight="bold" color="white">
                    <Link
                      href="https://copyrightblog.kluweriplaw.com/2023/05/09/generative-ai-copyright-and-the-ai-act/"
                      target="_blank"
                    >
                      {"Wolters Kluwer\u21D7"}
                    </Link>
                  </MKTypography>
                </MKBox>
                <MKBox mt={4} sx={{ color: ({ palette: { white } }) => white.main }}>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="light"
                    mb={0}
                    sx={{ fontSize: "1rem" }}
                  >
                    &ldquo;This means that there is a huge opportunity for creators to build new
                    collective structures for exercising their rights vis-а-vis commercial ML
                    developers.&rdquo;
                  </MKTypography>
                  <MKTypography variant="button" fontWeight="bold" color="white">
                    <Link
                      href="https://openfuture.eu/blog/protecting-creatives-or-impeding-progress/"
                      target="_blank"
                    >
                      {"Open Future\u21D7"}
                    </Link>
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                  Respecting Opt-Outs
                </MKTypography>
                <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2}>
                  is required for commercial text and data mining in the EU.
                </MKTypography>
                <MKTypography
                  component="a"
                  href="https://copyrightblog.kluweriplaw.com/2019/07/24/the-new-copyright-directive-text-and-data-mining-articles-3-and-4/"
                  target="_blank"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Learn More\u21D7"}
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Testimonials;
