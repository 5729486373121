import React, { useState, useEffect } from "react";
import MKBox from "components/MKBox";
import Toggle from "components/Toggle/Toggle";
import { aiTxtOptions } from "utils/aiTxtOptions";
import { determineAllowedTypes, determineBlockedTypes, handleDownload } from "./helpers";
import MKButton from "components/MKButton";
import MKTable from "components/MKTable";
import { Container, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MKTypography from "components/MKTypography";

const AiTxtGenerator = () => {
  const [aiTxtGeneratorOptions, setAiTxtGeneratorOptions] = useState(aiTxtOptions);
  const [blockedTypes, setBlockedTypes] = useState([]);
  const [allowedTypes, setAllowedTypes] = useState([]);

  useEffect(() => {
    const blockedOptions = determineBlockedTypes(aiTxtGeneratorOptions);
    const allowedOptions = determineAllowedTypes(aiTxtGeneratorOptions);

    setBlockedTypes(blockedOptions);
    setAllowedTypes(allowedOptions);
  }, [aiTxtGeneratorOptions]);

  const handleOnToggle = (name, isBlocked) => {
    const newArr = aiTxtGeneratorOptions.map((option) =>
      option.name === name ? { ...option, isBlocked: !isBlocked } : option
    );

    setAiTxtGeneratorOptions(newArr);
  };

  const rows = aiTxtGeneratorOptions.map((option) => {
    const { name, isBlocked, icon } = option;

    return {
      [""]: (
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <MKTypography
            as="span"
            sx={{
              textTransform: "capitalize",
              fontSize: "18px",
            }}
          >
            {name}
          </MKTypography>

          {icon}
        </MKBox>
      ),
      Block: (
        <Toggle
          name={name}
          checked={isBlocked}
          onChange={() => handleOnToggle(name, isBlocked)}
          ariaLabel={`Block ${name} toggle`}
        />
      ),
      Allow: (
        <Toggle
          name={name}
          checked={!isBlocked}
          onChange={() => handleOnToggle(name, isBlocked)}
          ariaLabel={`Allow ${name} toggle`}
        />
      ),
    };
  });

  return (
    <MKBox component="section" pt={4} pb={6} width="100%">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Grid container item xs={12} lg={10} mx="auto">
          <MKTable
            columns={[
              { name: "", align: "center" },
              { name: "Block", align: "center" },
              { name: "Allow", align: "center" },
            ]}
            rows={rows}
            aria-label="ai.txt generator table"
          />
        </Grid>
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <MKTypography variant="body2" color="text">
            By using the ai.txt generator, you agree to the{" "}
            <MKTypography
              component="a"
              href="https://site.spawning.ai/terms-of-service?ctx=ai-txt"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "underline !important",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              variant="body2"
              name="terms-of-service"
            >
              Terms of Service
            </MKTypography>
          </MKTypography>
          <MKButton
            variant="outlined"
            color="dark"
            onClick={() => {
              handleDownload(allowedTypes, blockedTypes);
            }}
          >
            Download ai.txt
            <DownloadIcon sx={{ ml: 1 }} />
          </MKButton>
        </MKBox>
      </Container>
    </MKBox>
  );
};

export default AiTxtGenerator;
