import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import PropTypes from "prop-types";

const Toggle = ({ label, defaultChecked = false, ariaLabel, ...props }) => {
  const [checked, setChecked] = useState(defaultChecked || false);

  if (label) {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onKeyDown={(e) => {
              if (e.key === "Enter") setChecked(!checked);
            }}
            onChange={() => setChecked(!checked)}
            inputProps={{
              "aria-label": ariaLabel || "Toggle switch",
            }}
            {...props}
          />
        }
        label={label}
      />
    );
  }

  return (
    <Switch
      checked={checked}
      onKeyDown={(e) => {
        if (e.key === "Enter") setChecked(!checked);
      }}
      onChange={() => setChecked(!checked)}
      inputProps={{
        "aria-label": ariaLabel || "Toggle switch",
      }}
      {...props}
    />
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default Toggle;
