// Sections
import Header from "sections/Header";
import Features from "sections/CardGrid";
import SectionTitle from "sections/SectionTitle";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/header-datadiligence.jpg";

function DataDiligence() {
  return (
    <>
      <SEO
        title="Data Diligence by Spawning"
        description="Respect rights holder opt-outs with only a few lines of code."
      />
      <Header
        image={bgImage}
        title="Data Diligence"
        subtitle="Respect rights holder opt-outs with only a few lines of code"
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkAriaLabel: "Learn more about Data Diligence",
            linkExt: false,
          },
          {
            link: "https://github.com/Spawning-Inc/datadiligence",
            linkText: "View on GitHub",
            linkExt: true,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <Features
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Respect all Opt-Outs",
              description:
                "Consolidates opt outs from the Spawning API, ai.txt, tdmrep.json, DeviantArt's NoAI headers, and more.",
              direction: "center",
            },
            {
              title: "Integrated with img2dataset",
              description:
                "Spawning's fork of img2dataset is a simple way to download from text-to-image datasets while respecting rights holder wishes.",
              direction: "center",
            },
            {
              title: "Easy to use",
              description:
                "It only takes a few extra lines of python code to check Spawning's Do Not Train registry and other TDM opt-out methods.",
              direction: "center",
            },
          ]}
        />
        <SectionTitle pretitle="data diligence" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What is Data Diligence?",
              answer:
                "Data Dilligence is a python package that aims to make it simple for ML practitioners to respect opt-outs in their training by providing a consistent interface to check if a given work is opted-out using any known method. The goal of this project is to make respecting opt-outs as painless as possible, while being flexible enough to support new opt-out methods as they are developed.",
            },
            {
              question: "Why is this needed?",
              answer:
                "ML training datasets are often harvested without consent from the data or content owners, meaning any ML models trained with these datasets could be violating the wishes of rights holders on how their content is used. With the absence of an opt-out standard, many platforms and individuals have come up with their own methods of stating their consent. Additionally, consent can change over time, and static datasets obviously cannot. A work which was consenting at the time of the dataset's creation may not be consenting at the time of training. Keeping up with the current state of opt-outs is unrealistic for most practitioners, and so this project aims to make it as easy as possible to respect opt-outs in your training pipeline.",
            },
            {
              question: "What is the difference between Data Diligence and the Spawning API?",
              answer:
                "The Spawning API provides Do Not Train information registered via our services, like 'Have I Been Trained' and ai.txt files. Other opt-out methods, like DeviantArt's NoAI headers, are attached to the download response or media files themselves. Data Diligence provides an easy interface to the Spawning API and additional interfaces for checking downloaded content for additional opt-out request types.",
            },
          ]}
        />
      </MKBox>
    </>
  );
}

export default DataDiligence;
