export const determineBlockedTypes = (configs) => {
  const blockedArr = [];

  configs.forEach(({ isBlocked, value, name }) => {
    if (isBlocked) {
      blockedArr.push({ value, name });
    }
  });

  const formattedArr = blockedArr.map((item) => ({
    value: item.value,
    name: item.name,
  }));

  return formattedArr;
};

export const determineAllowedTypes = (configs) => {
  const allowedArr = [];

  configs.forEach(({ isBlocked, value, name }) => {
    if (!isBlocked) {
      allowedArr.push({ value, name });
    }
  });

  const formattedArr = allowedArr.map((item) => ({
    value: item.value,
    name: item.name,
  }));

  return formattedArr;
};

export const handleDownload = (allowedTypes, blockedTypes) => {
  let allowedText = ``;
  let blockedText = ``;

  const allowedValues = allowedTypes.map((allowedType) => allowedType.value);

  allowedText += `${allowedValues.map((value) =>
    value.map((file) => {
      if (file) {
        return `\nAllow: ${file}`;
      }

      return "";
    })
  )}`;

  allowedText = allowedTypes.length === 0 ? "" : `${allowedText.replaceAll(",", "")}`;

  const blockedValues = blockedTypes.map((blockedType) => blockedType.value);

  blockedText += `${blockedValues.map((value) =>
    value.map((file) => {
      if (file) {
        return `\nDisallow: ${file}`;
      }

      return "";
    })
  )}`;

  blockedText = blockedTypes.length === 0 ? "" : `${blockedText.replaceAll(",", "")}`;

  const isTextApproved = allowedTypes.find((i) => i.name === "text");
  const catchAllValue = allowedTypes.length === 0 ? `Disallow: /\nDisallow: *` : `Disallow: /`;

  let finalText = `\nUser-Agent: *${blockedText}${allowedText}\n${
    isTextApproved ? "Allow: /" : catchAllValue
  }`;

  finalText = `\nUser-Agent: *${blockedText}${allowedText}\n${
    isTextApproved ? "Allow: /" : catchAllValue
  }`;

  if (allowedTypes.length === 0) {
    finalText = `\nUser-Agent: *\n${isTextApproved ? "Allow: /" : catchAllValue}`;
  }

  const pre = `# Spawning AI\n# Prevent datasets from using the following file types\n`;
  const element = document.createElement("a");
  const file = new Blob([`${pre}${finalText}`], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = "ai.txt";
  document.body.appendChild(element);
  element.click();
};
