// Sections
import Header from "sections/Header";
import Faq from "sections/Faq";
import Tdm from "sections/Tdm";

// Components
import SEO from "components/SEO";

// Images
import bgImage from "assets/images/header-rhfaq.jpg";

function RightsHoldersFaq() {
  return (
    <>
      <SEO
        title="Spawning FAQ for Rights Holders"
        description="Frequently Asked Questions about Spawning and our products."
      />
      <Header title="FAQ for Rights Holders" image={bgImage} half />
      <Faq
        questions={[
          {
            question: "What is the Do Not Train registry?",
            answer:
              "The Do Not Train registry is a designated list where individuals can flag their intellectual property to ensure that they are not used in the creation of future datasets for training Artificial Intelligence (AI) models. When you add your data to this registry, it signals to AI developers and companies that you do not consent for your data to be used to train AI. It serves as an additional layer of control and security for individuals who want to retain authority over how their data is used in the rapidly expanding field of AI.",
          },
          {
            question: "Can I remove data from currently trained models?",
            answer:
              "No, adding to the Do Not Train registry will not remove your data from models that have already been trained. However, it will only prevent your data from being used in future training sets and any models trained in the future on existing datasets that included your work.",
          },
          {
            question: "Which organizations have agreed to honor the Do Not Train registry?",
            answer:
              "We have a growing list of partnerships with AI companies that use our APIs for managing their training datasets. <a href='https://huggingface.co' target='_blank'>HuggingFace</a> (the largest repository of models and datasets) and <a href='https://stability.ai' target='_blank'>Stability</a> (creators of Stable Diffusion) have both agreed to respect the Do Not Train registry.",
          },
          {
            question: "What is the purpose of Have I Been Trained?",
            answer:
              "'Have I Been Trained?' helps you identify and manage the use of your images in public datasets for training large language models. By allowing you to claim your domain and set permissions on the usage of your images, we aim to give rights holders more control over their work.",
          },
          {
            question: "What is the Browser Extension?",
            answer:
              "The Spawning Browser Extension is a tool that allows you to discover if the data on the web pages you browse has been included in public AI training sets. It provides insights, analytics, and control over your data usage.",
          },
          {
            question: "What is an ai.txt file?",
            answer:
              "An ai.txt file is a simple text file placed in the root directory (or .well-known/) of your website that communicates with data miners. It provides instructions on whether the text and media files hosted on your domain can be used to train commercial AI models.",
          },
          {
            question: "Why is our work not opted out automatically?",
            answer:
              "The practice of data scraping is an industry standard that we do not control. Spawning created 'Have I Been Trained?' as a universal opt-out platform to give rights holders some degree of control over how their work is used to train AI models. We offer this solution to encourage commercial AI trainers to comply with the EU’s TDM exceptions, and to respect the wishes of rights holders.",
          },
          {
            question: "Doesn't opt-out protect AI companies over artists?",
            answer:
              "We believe that requiring the informed consent of the rights holders whose work is used to train AI is the ideal solution. For now, with most AI companies scraping the web without any consideration for rights holders, we see respecting opt-outs as a practical step forward. We do not want rights holders to be forced to opt out. We want them to have the option to opt out.",
          },
        ]}
      />
      <Tdm />
    </>
  );
}

export default RightsHoldersFaq;
