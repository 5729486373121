// Sections
import Header from "sections/Header";
import Features from "sections/CardGrid";
import SectionTitle from "sections/SectionTitle";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/header-haveibeentrained.jpg";

function HaveIBeenTrained() {
  return (
    <>
      <SEO
        title="Have I Been Trained"
        description="Search for your images in the most popular AI image dataset, LAION-5B."
      />
      <Header
        image={bgImage}
        title="Have I Been Trained?"
        subtitle="Search for your images in the most popular AI image dataset, LAION-5B"
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkAriaLabel: "Learn more about Have I Been Trained",
            linkExt: false,
          },
          {
            link: "https://haveibeentrained.com",
            linkText: "Try HaveIBeenTrained",
            linkExt: true,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <Features
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Search by Text",
              description:
                "Our text search finds exact matches from image captions, and lets you describe images.",
              direction: "center",
            },
            {
              title: "Search by Image",
              description:
                "Search with an image, and we'll find the most similar images in the datasets. We do not store any images used to search.",
              direction: "center",
            },
            {
              title: "Search by Website",
              description:
                "We'll show you every image scraped from your website, and give you the option to premptively opt out anything you add to your website in the future.",
              direction: "center",
            },
          ]}
        />
        <SectionTitle pretitle="Have I Been Trained?" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What is the purpose of Have I Been Trained?",
              answer:
                "'Have I Been Trained?' helps you identify and manage the use of your images in public datasets for training large language models. By allowing you to claim your domain and set permissions on the usage of your images, we aim to give you more control over your creative works.",
            },
            {
              question: "What is the Do Not Train registry?",
              answer:
                "The Do Not Train registry is a designated list where individuals can flag their intellectual property to ensure that they are not used in the creation of future datasets for training Artificial Intelligence (AI) models. When you add your data to this registry, it signals to AI developers and companies that you do not consent for your data to be used to train AI. It serves as an additional layer of control and security for individuals who want to retain authority over how their data is used in the rapidly expanding field of AI.",
            },
            {
              question: "What are public training sets?",
              answer:
                "Public training sets are collections of data that are used to train AI systems. They usually consist of text and links to media files from various sources, including websites, social media, and other digital platforms. Have I Been Trained searches images that are included in some of these datasets.",
            },
            {
              question: "How are these datasets used in AI training?",
              answer:
                "AI Organizations like <a href='https://stability.ai' target='_blank'>Stability</a> and <a href='https://openai.com' target='_blank'>OpenAI</a> download the media files from their links in these datasets and present them to the AI model along with their captions for training.",
            },
            {
              question: "Can I remove data from currently trained models?",
              answer:
                "No, adding to the Do Not Train registry will not remove your data from models that have already been trained. However, it will only prevent your data from being used in future training sets and any models trained in the future on existing datasets that included your work.",
            },
            {
              question: "How does the search functionality work on the site?",
              answer:
                "Our search functionality allows users to search for images in three ways. 1) Uploading a specific image: The search will return all images that are similar or the same to the image searched, comparing it against the public datasets used to train the models. 2) Searching by a domain: The search will look at the domain and find any public images that are on that site and index them against the public datasets. This includes searching all of the site's subdomains. 3) Search by text: Search by text uses caption search and the clip model trained by OpenAI to find images that were tagged with your search terms or are closely related to the text input.",
            },
            {
              question: "Which organizations have agreed to honor the Do Not Train registry?",
              answer:
                "We have a growing list of partnerships with AI companies that use our APIs for managing their training datasets. <a href='https://huggingface.co' target='_blank'>HuggingFace</a> (the largest repository of models and datasets) and <a href='https://stability.ai' target='_blank'>Stability</a> (creators of Stable Diffusion) have both agreed to respect the Do Not Train registry.",
            },
            {
              question: "Why is our work not opted out automatically?",
              answer:
                "The practice of data scraping is an industry standard that we do not control. Spawning created 'Have I Been Trained?' as a universal opt-out platform to give rightsholders some degree of control over how their work is used to train AI models. We offer this solution to encourage commercial AI trainers to comply with the EU’s TDM exceptions, and to respect the wishes of rights holders.",
            },
          ]}
        />
      </MKBox>
    </>
  );
}

export default HaveIBeenTrained;
