// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Components
import MKBox from "components/MKBox";
import { Link } from "@mui/material";

// Images
import huggingface from "assets/images/logo-huggingface.svg";
import stability from "assets/images/logo-stability.svg";
import shutterstock from "assets/images/logo-shutterstock.svg";
import artstation from "assets/images/logo-artstation.svg";

function Logos() {
  return (
    <MKBox pt={4} pb={6}>
      <Container>
        <Grid container spacing={3} sx={{ px: { xs: 2, md: 4, lg: 8 } }}>
          <Grid item xs={6} lg={3}>
            <Link href={"https://huggingface.co"} target="_blank">
              <MKBox
                component="img"
                src={huggingface}
                alt="huggingface"
                width="100%"
                opacity={0.9}
              />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link href={"https://stability.ai"} target="_blank">
              <MKBox component="img" src={stability} alt="stability" width="100%" opacity={0.9} />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link href={"https://shutterstock.com"} target="_blank">
              <MKBox
                component="img"
                src={shutterstock}
                alt="shutterstock"
                width="100%"
                opacity={0.9}
              />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link href={"https://artstation.com"} target="_blank">
              <MKBox component="img" src={artstation} alt="artstation" width="100%" opacity={0.9} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Logos;
