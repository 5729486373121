/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import { Link } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ComplexNewsCard({ image, link, color, outlet, title, quote }) {
  return (
    <Grid container px={8} alignItems="center" height={{ xs: "auto", md: "450px" }} spacing={3}>
      <Grid item xs={10} md={6} margin="auto" textAlign={"center"}>
        <Link href={link} target="_blank">
          <MKBox pl={{ xs: 0, md: 2 }} width="100%">
            <MKBox
              component="img"
              src={image}
              alt={title}
              width="100%"
              borderRadius="md"
              shadow="md"
            />
          </MKBox>
        </Link>
      </Grid>
      <Grid
        item
        xs={10}
        md={6}
        lg={5}
        sx={{ mr: { xs: "auto", lg: "auto" }, ml: { xs: "auto", lg: 0 } }}
      >
        <Link href={link} target="_blank">
          <MKTypography variant="button" color="text" fontWeight="bold" mb={0.5}>
            {outlet}
          </MKTypography>
        </Link>
        <Link href={link} target="_blank">
          <MKTypography variant="h3" color={color}>
            {title}
          </MKTypography>
        </Link>
        <Link href={link} target="_blank">
          <MKTypography variant="body2" color="text" my={1.5}>
            <em>&quot;{quote}&quot;</em>
          </MKTypography>
        </Link>
      </Grid>
    </Grid>
  );
}

// Setting default props for the ComplexNewsCard
ComplexNewsCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the ComplexNewsCard
ComplexNewsCard.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "error",
    "warning",
    "dark",
    "light",
  ]),
  title: PropTypes.string.isRequired,
  outlet: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export default ComplexNewsCard;
