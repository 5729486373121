// Sections
import Header from "sections/Header";
import Faq from "sections/Faq";
import Tdm from "sections/Tdm";

// Components
import SEO from "components/SEO";

// Images
import bgImage from "assets/images/header-developerfaq.jpg";

function DevelopersFaq() {
  return (
    <>
      <SEO
        title="Spawning FAQ for Developers"
        description="Frequently Asked Questions about Spawning and our products."
      />
      <Header title="FAQ for Developers" image={bgImage} half />
      <Faq
        questions={[
          {
            question: "What is the Do Not Train registry?",
            answer:
              "The Do Not Train registry is a designated list where individuals can flag their intellectual property to ensure that they are not used in the creation of future datasets for training Artificial Intelligence (AI) models. When you add your data to this registry, it signals to AI developers and companies that you do not consent for your data to be used to train AI. It serves as an additional layer of control and security for individuals who want to retain authority over how their data is used in the rapidly expanding field of AI.",
          },
          {
            question: "What is the purpose of Spawning's API?",
            answer:
              "Spawning's API is designed to help companies and individual researchers manage and validate their datasets by checking against our Do Not Train registry. The API allows users to update their products and datasets, ensuring that non-consenting data is not used, promoting ethical data usage, and reducing potential legal risks.",
          },
          {
            question: "Who can benefit from using Spawning's API?",
            answer:
              "Companies and individuals who work with datasets, train AI models, or aggregate data can benefit from using Spawning's API. Our API helps our users ensure that their datasets and models are compliant with data consent regulations.",
          },
          {
            question: "How does Spawning's API work?",
            answer:
              "Spawning's API enables users to submit large lists of URLs to our API, which are then checked for any data that has been opted out by the copyright owners.",
          },
          {
            question: "How do I get started with Spawning's API?",
            answer:
              "To get started with Spawning's API, <a href='https://haveibeentrained.com/api' target='_blank'>sign up</a> for a free API key. Once you have your API key, you can access our <a href='https://opts-api.spawningaiapi.com/docs' target='_blank'>API documentation</a>, which provides detailed information on how to integrate the API with your application and use it to validate your datasets.",
          },
          {
            question: "What is Data Diligence?",
            answer:
              "Data Dilligence is a python package that aims to make it simple for ML practitioners to respect opt-outs in their training by providing a consistent interface to check if a given work is opted-out using any known method. The goal of this project is to make respecting opt-outs as painless as possible, while being flexible enough to support new opt-out methods as they are developed.",
          },
          {
            question: "Why is this needed?",
            answer:
              "ML training datasets are often harvested without consent from the data or content owners, meaning any ML models trained with these datasets could be violating the wishes of rights holders on how their content is used. With the absence of an opt-out standard, many platforms and individuals have come up with their own methods of stating their consent. Additionally, consent can change over time, and static datasets obviously cannot. A work which was consenting at the time of the dataset's creation may not be consenting at the time of training. Keeping up with the current state of opt-outs is unrealistic for most practitioners, and so this project aims to make it as easy as possible to respect opt-outs in your training pipeline.",
          },
          {
            question: "What is the difference between Data Diligence and the Spawning API?",
            answer:
              "The Spawning API provides Do Not Train information registered via our services, like 'Have I Been Trained' and ai.txt files. Other opt-out methods, like DeviantArt's NoAI headers, are attached to the download response or media files themselves. Data Diligence provides an easy interface to the Spawning API and additional interfaces for checking downloaded content for additional opt-out request types.",
          },
        ]}
      />
      <Tdm />
    </>
  );
}

export default DevelopersFaq;
