// Sections
import Header from "sections/Header";
import Features from "sections/CardGrid";
import SectionTitle from "sections/SectionTitle";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/header-spawningapi.jpg";

function SpawningApi() {
  return (
    <>
      <SEO
        title="Spawning API"
        description="Our API serves opt-out information for 1.5 Billion URLs (and counting) without slowing down your model training."
      />
      <Header
        image={bgImage}
        title="Spawning API"
        subtitle="Our API serves opt-out information for 1.5 Billion URLs (and counting) without slowing down your model training."
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkAriaLabel: "Learn more about Spawning API",
            linkExt: false,
          },
          {
            link: "https://haveibeentrained.com/api-key",
            linkText: "Sign up for an API key",
            linkExt: true,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <Features
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Secure",
              description:
                "API requests only return 'Do Not Train' flags for known links and don't reveal the rights holder's personal information.",
              direction: "center",
            },
            {
              title: "Fast",
              description:
                "Our API returns flags for up 10k images per request and scales linearly to meet the most demanding parallel runs.",
              direction: "center",
            },
            {
              title: "Up to date",
              description:
                "We scan for known opt-out methods and cache them with our API. Never miss a permission change for training data.",
              direction: "center",
            },
          ]}
        />
        <SectionTitle pretitle="Spawning API" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What is the purpose of Spawning's API?",
              answer:
                "Spawning's API is designed to help companies and individual researchers manage and validate their datasets by checking against our Do Not Train registry. The API allows users to update their products and datasets, ensuring that non-consenting data is not used, promoting ethical data usage, and reducing potential legal risks.",
            },
            {
              question: "Who can benefit from using Spawning's API?",
              answer:
                "Companies and individuals who work with datasets, train AI models, or aggregate data can benefit from using Spawning's API. Our API helps our users ensure that their datasets and models are compliant with data consent regulations.",
            },
            {
              question: "How does Spawning's API work?",
              answer:
                "Spawning's API enables users to submit large lists of URLs to our API, which are then checked for any data that has been opted out by the copyright owners.",
            },
            {
              question: "How do I get started with Spawning's API?",
              answer:
                "To get started with Spawning's API, <a href='https://haveibeentrained.com/api' target='_blank'>sign up</a> for a free API key. Once you have your API key, you can access our <a href='https://opts-api.spawningaiapi.com/docs' target='_blank'>API documentation</a>, which provides detailed information on how to integrate the API with your application and use it to validate your datasets.",
            },
          ]}
        />
      </MKBox>
    </>
  );
}

export default SpawningApi;
