import NotesIcon from "@mui/icons-material/Notes";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AlbumIcon from "@mui/icons-material/Album";
import TheatersIcon from "@mui/icons-material/Theaters";
import DataObjectIcon from "@mui/icons-material/DataObject";

export const aiTxtOptions = [
  {
    name: "text",
    value: [
      "*.txt",
      "*.pdf",
      "*.doc",
      "*.docx",
      "*.odt",
      "*.rtf",
      "*.tex",
      "*.wks",
      "*.wpd",
      "*.wps",
      "*.html",
    ],
    isBlocked: true,
    icon: <NotesIcon fontSize="medium" />,
  },
  {
    name: "images",
    value: [
      "*.bmp",
      "*.gif",
      "*.ico",
      "*.jpeg",
      "*.jpg",
      "*.png",
      "*.svg",
      "*.tif",
      "*.tiff",
      "*.webp",
    ],
    isBlocked: true,
    icon: <CameraAltIcon fontSize="medium" />,
  },
  {
    name: "audio",
    value: [
      "*.aac",
      "*.aiff",
      "*.amr",
      "*.flac",
      "*.m4a",
      "*.mp3",
      "*.oga",
      "*.opus",
      "*.wav",
      "*.wma",
    ],
    isBlocked: true,
    icon: <AlbumIcon fontSize="medium" />,
  },
  {
    name: "video",
    value: ["*.mp4", "*.webm", "*.ogg", "*.avi", "*.mov", "*.wmv", "*.flv", "*.mkv"],
    isBlocked: true,
    icon: <TheatersIcon fontSize="medium" />,
  },
  {
    name: "code",
    value: [
      "*.py",
      "*.js",
      "*.java",
      "*.c",
      "*.cpp",
      "*.cs",
      "*.h",
      "*.css",
      "*.php",
      "*.swift",
      "*.go",
      "*.rb",
      "*.pl",
      "*.sh",
      "*.sql",
    ],
    isBlocked: true,
    icon: <DataObjectIcon fontSize="medium" />,
  },
];
