// Sections
import Header from "sections/Header";
import CardGrid from "sections/CardGrid";
import SectionTitle from "sections/SectionTitle";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import YoutubeEmbed from "components/YoutubeEmbed";
import MKBox from "components/MKBox";
import AiTxtGenerator from "sections/AiTxtGenerator/AiTxtGenerator";

// Images
import bgImage from "assets/images/header-aitxt.jpg";
import wordpressImage from "assets/images/logo-wordpress.png";
import squarespaceImage from "assets/images/logo-squarespace.png";
import shopifyImage from "assets/images/logo-shopify.png";
import wixImage from "assets/images/logo-wix.png";
import webflowImage from "assets/images/logo-webflow.png";
import sshImage from "assets/images/logo-ssh.jpg";
import ftpImage from "assets/images/logo-ftp.svg";

const AiTxt = () => {
  return (
    <>
      <SEO
        title="Spawning ai.txt"
        description="Keep yourself searchable, while restricting AI training."
      />
      <Header
        image={bgImage}
        title="ai.txt"
        subtitle="Keep yourself searchable, while restricting AI training"
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkAriaLabel: "Learn more about ai.txt",
            linkExt: false,
          },
          {
            link: "#create",
            linkText: "Create an ai.txt file",
            linkExt: false,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <CardGrid
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Granular Control",
              description:
                "Ai.txt files allow you to set specific rules for commercial AI training, granting or denying access to each of your website's media types.",
              direction: "center",
            },
            {
              title: "Real-Time Protection",
              description:
                "Ai.txt files are read when media is downloaded (rather than when content is crawled), and can be respected even if links to your media have already been added to an existing dataset.",
              direction: "center",
            },
            {
              title: "Extensive Compatibility",
              description:
                "Spawning's API communicates the permissions set by ai.txt files. For organizations who don't use Spawning's API, an ai.txt file provides a machine readable opt-out that can be easily parsed and respected.",
              direction: "center",
            },
          ]}
        />
        <SectionTitle pretitle="ai.txt" title="What is ai.txt?" />
        <YoutubeEmbed embedId="ec5WhxInWi8" />
        <MKBox id="create" pt={8} mt={-8}>
          <SectionTitle pretitle="ai.txt" title="Create an ai.txt" />
          <AiTxtGenerator />
        </MKBox>
        <SectionTitle pretitle="ai.txt" title="Publish your ai.txt" />
        <CardGrid
          cols={4}
          cardType="InfoBg"
          cards={[
            {
              pretitle: "Deploy to",
              title: "Wordpress",
              image: wordpressImage,
              link: "https://site.spawning.ai/tutorials/wordpress",
            },
            {
              pretitle: "Deploy to",
              title: "Squarespace",
              image: squarespaceImage,
              link: "https://site.spawning.ai/tutorials/squarespace",
            },
            {
              pretitle: "Deploy to",
              title: "Shopify",
              image: shopifyImage,
              link: "https://site.spawning.ai/tutorials/shopify",
            },
            {
              pretitle: "Deploy to",
              title: "Wix",
              image: wixImage,
              link: "https://site.spawning.ai/tutorials/wix",
            },
            {
              pretitle: "Deploy to",
              title: "Webflow",
              image: webflowImage,
              link: "https://site.spawning.ai/tutorials/webflow",
            },
            {
              pretitle: "Deploy to",
              title: "SSH",
              image: sshImage,
              link: "https://site.spawning.ai/tutorials/ssh",
            },
            {
              pretitle: "Deploy to",
              title: "FTP",
              image: ftpImage,
              link: "https://site.spawning.ai/tutorials/ftp",
            },
          ]}
        />
        <SectionTitle pretitle="ai.txt" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What is an ai.txt file?",
              answer:
                "An ai.txt file is a simple text file placed in the root directory (or .well-known/) of your website that communicates with data miners. It provides instructions on whether the text and media files hosted on your domain can be used to train commercial AI models.",
            },
            {
              question: "How do I create an ai.txt file using the ai.txt generator?",
              answer:
                "To create an ai.txt file using our generator, simply toggle the options for images and text according to your preferences. Once you've set your desired permissions, click the 'Download ai.txt' button to create your custom ai.txt file.",
            },
            {
              question: "How do I upload an ai.txt file to my website?",
              answer:
                "Upload the ai.txt file to the root directory (/ or /.well-known/) of your website, alongside your robots.txt file. You can do this using your hosting provider's File Manager or via an FTP client.",
            },
            {
              question: "Will an ai.txt file impact my website's SEO?",
              answer:
                "No, the ai.txt file is specifically designed for AI miners and does not impact traditional search engine crawlers or your website's SEO.",
            },
            {
              question: "How do I know if the ai.txt file is working?",
              answer:
                "To verify that your ai.txt file is in the correct location, visit yourdomain.com/ai.txt in your browser, replacing yourdomain.com with your actual domain name. If you see the contents of the ai.txt file as plain text, it's in the right place and ready to communicate with Spawning's API.",
            },
            {
              question: "Can I update my ai.txt file if my preferences change?",
              answer:
                "Yes, you can update your ai.txt file at any time by generating a new file using our ai.txt Generator and re-uploading it to your website's root directory. Remember to remove or overwrite the old ai.txt file with the updated version.",
            },
            {
              question: "Do all AI miners follow the rules set in my ai.txt file?",
              answer:
                "AI miners who use Spawning's API will respect AI.txt, but most do not (yet). However, implementing an ai.txt file is still an effective way to communicate your preferences to responsible AI developers and miners, promoting ethical AI practices.",
            },
            {
              question: "Is the ai.txt generator free to use?",
              answer:
                "Yes, our ai.txt generator is free to use. We believe in providing accessible tools to help rights holders maintain control over their content and encourage responsible AI development. If you have any other questions or need further assistance, please don't hesitate to reach out to our support team.",
            },
          ]}
        />
      </MKBox>
    </>
  );
};

export default AiTxt;
