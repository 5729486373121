// Sections
import Header from "sections/Header";
import SectionTitle from "sections/SectionTitle";
import ProductsGrid from "sections/CardGrid";
import CardList from "sections/CardList";
import Tdm from "sections/Tdm";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";

// Images
import bgImage from "assets/images/header-home.jpg";
import MKTypography from "components/MKTypography";

function Spawning() {
  return (
    <>
      <SEO />
      <Header
        image={bgImage}
        title="Data Governance for Generative AI"
        subtitle="Quality | Safety | Consent"
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkAriaLabel: "Learn more about Spawning and our products",
            linkExt: false,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <Grid container xs={10} mx="auto" pb={4}>
          <Grid item xs={12} lg={4} mx="auto">
            <SectionTitle pretitle="Do Not Train" title="Tool Suite" />
          </Grid>
          <Grid item xs={12} lg={8} pt={4} mx="auto">
            <MKTypography variant="body2">
              Spawning is building an ecosystem-wide solution to meet the needs of rights holders
              and AI developers. Spawning’s Do Not Train Tool Suite consolidates machine-readable
              opt-out methods around the Do Not Train Registry, making it simple to declare data
              preferences and to respect them.
            </MKTypography>
          </Grid>
        </Grid>

        <SectionTitle pretitle="Tools For" title="Rights Holders" />
        <Grid container xs={10} lg={6} mx="auto" textAlign="center">
          <MKTypography variant="body2">
            Rights holder tools let you add media to Spawning’s Do Not Train Registry and redirect
            AI scraping traffic. The Do Not Train Registry, which signals the data-use preferences
            for over 1.5 billion individual works, is respected by partners such as Stability AI and
            Hugging Face.
          </MKTypography>
        </Grid>
        <ProductsGrid
          cols={3}
          cardType="ColoredBg"
          cards={[
            {
              // image: cardImage,
              label: "Search and Discover",
              title: "Have I Been Trained",
              description:
                "Search for your work in popular AI datasets. Add your domains and images to our Do Not Train registry.",
              action: {
                type: "internal",
                route: "/have-i-been-trained",
                label: "get started",
              },
            },
            {
              // image: cardImage,
              label: "Protect Your Website",
              title: "Kudurru",
              description: "Block active AI web scrapers from accessing your website's content.",
              action: {
                type: "external",
                route: "https://kudurru.ai",
                label: "get started",
              },
            },
            {
              // image: cardImage,
              label: "Opt Out Anything",
              title: "Spawning Browser Extension",
              description:
                "Add any media to our Do Not Train Registry, anywhere it appears on the web.",
              action: {
                type: "internal",
                route: "/browser-extension",
                label: "get started",
              },
            },
          ]}
        />
        <SectionTitle pretitle="Tools For" title="Developers" />
        <Grid container xs={10} lg={6} mx="auto" textAlign="center">
          <MKTypography variant="body2">
            Developer tools make it easy and efficient to filter do-not-train requests. Spawning’s
            Data Diligence package and API surface all machine-readable rights reservation methods
            and keeps developers in compliance with the EU’s TDM Copyright Exceptions.
          </MKTypography>
        </Grid>
        <CardList
          card={{
            // image: cardImage,
            title: "Data Diligence",
            label: "Respect Rights Reservations",
            description: "Skip items marked as 'do not train' with our open source python package.",
            action: {
              type: "internal",
              route: "/data-diligence",
              label: "get started",
            },
          }}
          list={[
            {
              title: "Respect all Machine Readable Rights Reservations",
              description:
                "Consolidates rights reservations from the Spawning API, ai.txt, tdmrep.json, DeviantArt's NoAI headers, and more.",
            },
            {
              title: "Integrated with img2dataset",
              description:
                "Our fork of img2dataset is a simple way to download from text-to-image datasets while respecting rights reservations.",
            },
            {
              title: "Easy to use",
              description:
                "Comply with the EU's TDM copyright exceptions by adding only a few lines of code. Check out our documentation to learn how easy it can be!",
            },
          ]}
          swap={false}
        />
        <CardList
          card={{
            // image: cardImage,
            title: "Spawning API",
            label: "Train Without Delays",
            description:
              "Our API serves do-not-train requests for 1.5 Billion URLs (and counting) without slowing down your model training.",
            action: {
              type: "internal",
              route: "/spawning-api",
              label: "get started",
            },
          }}
          list={[
            {
              title: "Secure",
              description:
                "API requests only return rights reservation flags for known links and don't reveal the rights holder's information.",
            },
            {
              title: "Fast",
              description:
                "Our API returns flags for up to 10k items per request and scales to meet the most demanding training runs.",
            },
            {
              title: "Up to date",
              description:
                "We scan for known rights reservation methods and cache them with our API. Never miss a permission change for training data.",
            },
          ]}
          swap={true}
        />
        <Tdm />
      </MKBox>
    </>
  );
}

export default Spawning;
